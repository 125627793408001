/* eslint-disable no-useless-escape */

import React from 'react'
import styled from 'styled-components'
import TransitionLink from 'gatsby-plugin-transition-link'
import { isMobile } from "react-device-detect";

const FeaturedProjectContainer = styled(TransitionLink)`
  display: flex;
  align-items: flex-end;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  height: 100vh;
  cursor: none;
  overflow: hidden;
  text-decoration: none;
`

const FeaturedProjectImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const FeaturedProjectVideo = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: ${props => props.theme.colors.lightGrey};
  
  > video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    transform: translateX(-50%) translateY(-50%);
  }
`

const FeaturedProjectVimeo = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
  height: auto;
  pointer-events: none;
  
  &::after {
    display: block;
    content: "";
    padding-bottom: 56.25%;
  }
  
  iframe {
    width: 100vw;
     height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
     min-height: 100vh;
     min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
     position: absolute;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
  }
`

const FeaturedProjectContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${props => props.theme.space[6]}px 2.4rem ${props => props.theme.space[7]}px 2.4rem;
  color: #fff;
  z-index: 100;
  
  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    flex-direction: row;
    justify-content: space-between;
    padding-left: 3.2rem;
    padding-right: 3.2rem;
    
    > div {
      display: inline-block;
      flex: 1 1 50%;
      max-width: 524px;
    }
    
    > h3 {
      flex: 1 1 50%;
      max-width: 400px;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints[2]}) {
    padding-left: 4.8rem;
    padding-right: 4.8rem;
  }
`

const Name = styled.h2`
  font-weight: 420;
  font-size: ${props => props.theme.fontSizes[5]}px;
  line-height: 1.1;
  margin-bottom: 0.8rem;
    
  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    font-size: ${props => props.theme.fontSizes[6]}px;
    margin: 0.4rem 0 0;
  }
`

const Description = styled.h3`
  font-weight: 430;
  font-size: ${props => props.theme.fontSizes[4]}px;
  line-height: 1.06;
  
  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    font-size: ${props => props.theme.fontSizes[5]}px;
    margin-top: 0.4rem;
  }  
`

export const FeaturedProject = ({ project }) => {
  const data = project.document.data

  const hasToutImage = typeof data.tout_image.url !== 'undefined'
  const hasVideo = (typeof data.tout_video.url !== 'undefined' && data.tout_video.url !== '') || (typeof data.feature_video.url !== 'undefined' && data.feature_video.url !== '' && !hasToutImage)
  const hasVimeo = data.tout_vimeo_video || (data.feature_vimeo_video && !hasToutImage)
  const hasImage = hasToutImage || typeof data.feature_image.url !== 'undefined'

  let videoSource;
  let vimeoSource;
  let imageSource;

  if (hasVideo) { videoSource = (typeof data.tout_video.url !== 'undefined' && data.tout_video.url !== '') ? data.tout_video.url : data.feature_video.url }
  if (hasVimeo) { vimeoSource = data.tout_vimeo_video ? data.tout_vimeo_video : data.feature_vimeo_video }
  if (hasImage) { imageSource = typeof data.tout_image.url !== 'undefined' ? data.tout_image.url : data.feature_image.url }

  return (
    <FeaturedProjectContainer
      to={project.url}
    >
      {(hasImage && ((!hasVideo && !hasVimeo) || isMobile)) && (
        <FeaturedProjectImage
          src={data.feature_image.url}
          alt={data.feature_image.alt}
        />
      )}

      <div>
        {(hasVimeo && !isMobile) && (
          <FeaturedProjectVimeo>
            <iframe
              src={`https://player.vimeo.com/video/${vimeoSource}?background=1`}
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
              title="Vimeo background"
            />
          </FeaturedProjectVimeo>
        )}

        {(hasVideo && !hasVimeo && !isMobile) && (
          <FeaturedProjectVideo>
            <video
              autoPlay
              muted
              loop
              poster={imageSource}
              playsInline
            >
              <source
                src={videoSource}
                type={`video/${videoSource.split('.').pop()}`}
              />
            </video>
          </FeaturedProjectVideo>
        )}
      </div>

      <FeaturedProjectContent>
        <div>
          {data.name && <Name>{data.name.text}</Name>}
        </div>

        {data.tagline && <Description>{data.tagline.text}</Description>}
      </FeaturedProjectContent>
    </FeaturedProjectContainer>
  )
}

export default FeaturedProject
