import React from 'react'
import styled, { keyframes } from 'styled-components'
import { Flex } from 'rebass'

const bounceIn = keyframes`
  0% {
		transform: translateY(-100%);
	}

	12% {
		transform: translateY(-89.11%);
	}

	24% {
		transform: translateY(-56.44%);
	}

	36% {
		transform: translateY(-1.99%);
	}

	54% {
		transform: translateY(-24.98%);
	}

	74% {
		transform: translateY(-1.63%);
	}

	82% {
		transform: translateY(-6.25%);
	}

	92% {
		transform: translateY(-0.66%);
	}

	96% {
		transform: translateY(-1.54%);
	}

	100% {
		transform: translateY(0);
	}
`


const ModalContainer = styled(Flex)`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.6);
  opacity: 0;
  visibility: hidden;
  z-index: 10000;
  transition: opacity 0.24s ease-in-out, visibility 0s 0.24s, transform 0.28s ease-in-out;
  align-items: center;
  justify-content: center;
  
  &.is-open {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.24s ease-in-out, visibility 0s 0s, transform 0.28s ease-in-out;
  }
`

const ModalClose = styled.button`
  position: absolute;
  top: 3.4rem;
  right: 9.6rem;
  color: #fff;
  appearance: none;
  border: 0 none;
  margin: 0;
  padding: 0;
  background: none;
  font-family: ${props => props.theme.fonts.variable};
  font-size: 20px;
  outline: none;
  cursor: pointer;
  user-select: none;
`

const ModalWrapper = styled.div``

const ModalVideo = styled.div`
  position: relative;
  width: 80vw;
  max-width: 1280px;
  
  &::after {
    content: "";
    display: block;
    padding-bottom: 56.25%;
  }
  
  .is-open & {
    animation-name: ${bounceIn};
    animation-fill-mode: forwards;
    animation-duration: 1s;
  }
  
  iframe {
    appearance: none;
    border: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const Modal = ({ isOpen, close }) => {
  return (
    <ModalContainer className={isOpen ? 'is-open' : ''}>
      <ModalClose onClick={() => {
        close()
      }}>close</ModalClose>

      <ModalWrapper>
        <ModalVideo>
          {isOpen && (
            <iframe
              title="Modal video"
              src="https://player.vimeo.com/video/391604277?autoplay=1"
              allowFullScreen
              allow="autoplay"/>
          )}
        </ModalVideo>
      </ModalWrapper>
    </ModalContainer>
  )
}

export default Modal
