/* eslint-disable no-useless-escape */

import React, { useRef, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import Arrow from './arrow.svg'
import Fade from 'react-reveal/Fade'
import Modal from './modal'
import { document } from 'browser-monads'
import VideoBackground from './video-background'

const fadeIn = keyframes`
  0% {
    transform: translateY(-32.27%);
  }

  38% {
    transform: translateY(-13.12%);
  }

  100% {
    transform: translateY(0%);
    opacity: 1;
  }
`

const IntroContainer = styled.div`
  display: flex;
  align-items: flex-end;
  box-sizing: border-box;
  position: relative;
  min-height: 60rem;
  justify-content: flex-start;
  padding: 0 2.6rem 14.4rem;

  &.is-difference {
    h1 span,
    button,
    a {
      mix-blend-mode: difference;
    }
  }

  &.is-light {
    h1 span,
    button,
    a {
      color: #fff;
    }

    button {
      mix-blend-mode: difference;
    }
  }

  h1 {
    position: relative;
    color: transparent;
    font-family: ${props => props.theme.fonts.variable};
    font-weight: 380;
    font-size: ${props => props.theme.fontSizes[6]}px;
    line-height: 0.98;
    max-width: 98rem;
    text-align: left;
    margin-bottom: 40px;
    user-select: none;

    span {
      --delay: calc((var(--char-index) * 5 + 5) * 3ms);
      display: inline-block;
      position: relative;
      color: ${props => props.theme.colors.black};
      animation-name: ${fadeIn};
      animation-fill-mode: forwards;
      animation-duration: 1.7s;
      animation-delay: var(--delay);
      opacity: 0;
      transform: translateY(-12px);

      &.whitespace {
        width: 1rem;
      }
    }
  }

  button,
  a {
    position: relative;
    color: inherit;
    text-decoration: none;
    appearance: none;
    border: 0 none;
    outline: none;
    box-shadow: none;
    background: transparent;
    padding: 24px 0;
    margin: 0;
    font-family: ${props => props.theme.fonts.variable};
    font-weight: 500;
    font-size: ${props => props.theme.fontSizes[3]}px;
    opacity: 0;

    img {
      position: relative;
      display: inline-block;
      margin-left: 0.8rem;
      width: 4.8rem;
      top: 0.2rem;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    min-height: 100vh;
    padding: 0 4.8rem 8rem;

    h1 {
      font-size: ${props => props.theme.fontSizes[7]}px;
      line-height: 1;
    }

    button,
    a {
      font-size: ${props => props.theme.fontSizes[4]}px;

      img {
        width: 6.4rem;
        top: 0.4rem;
      }
    }
  }

  @media (min-width: ${props => props.theme.breakpoints[2]}) {
    h1 {
      font-size: 8.2rem;
      line-height: 0.96;
      padding-right: 8rem;
    }
  }
`

const Intro = ({ text, buttonText, video, vimeo, poster, color, destination, variant }) => {
  import ('splitting').then(module => {
    const Splitting = module.default

    Splitting({
      by: 'chars',
    })
  }).catch(error => {
    console.log('An error occurred importing Splitting.JS')
  })

  const introRef = useRef()

  const [modalOpen, setModalOpen] = useState(false)

  const captureEscape = (event) => {
    if (event.keyCode === 27) {
      closeModal()
    }
  }

  const openModal = () => {
    setModalOpen(true)
    document.getElementsByTagName('body')[0].classList.add('is-locked')
  }

  const closeModal = () => {
    setModalOpen(false)
    document.getElementsByTagName('body')[0].classList.remove('is-locked')
  }

  document.addEventListener('keydown', captureEscape)

  return (
    <>
      <IntroContainer
        ref={introRef}
        className={variant ? `is-${variant}` : ''}
      >
        {((video && video.url) || vimeo) && (
          <VideoBackground
            source={video.url}
            vimeo={vimeo}
            poster={poster.url}
            color={color}
          />
        )}

        <div>
          {text && <h1 data-splitting>{text}</h1>}

          <Fade up distance="16px">
            {destination ? (
              <a
                href={destination}
                data-cursor
              >{ buttonText || 'Who we are' } <img src={Arrow} alt=''/></a>
            ) : (
              <button
                data-cursor
                data-icon="bacon"
                onClick={() => {
                  openModal()
                }}
              >{ buttonText || 'See the sizzle' } <img src={Arrow} alt=''/></button>
            )}
          </Fade>
        </div>
      </IntroContainer>

      <Modal
        isOpen={modalOpen}
        close={closeModal}
      />
    </>
  )
}

export default Intro
